import React from "react"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import ForthcomingAuctions from "../components/Content/ForthcomingAuctions"
import OnlineAuctions from "../components/Content/OnlineAuctions"

const Index = props => (
  <>
    <SEO title="Home" />
    <Layout location={props.location}>
      <ForthcomingAuctions />
      <OnlineAuctions />
    </Layout>
  </>
)

export default Index
