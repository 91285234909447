import React from "react"
import Button from "../../Button"

import "./index.scss"

const auctionsAppLink = process.env.AUCTIONS_APP_LINK

const OnlineAuctions = () => {
  return (
    <div className="row justify-content-center online-auctions">
      <div className="col-12 col-md-8 text-center">
        <h2 className="online-auctions-title">Join our online Auctions</h2>
        <h4 className="online-auctions-sub-title">
          Bid from wherever you are!
        </h4>
        <p>
          Register to become an online bidder for South Coast Auctions and bid
          on our live auctions from the comfort of your home.
        </p>
        <a href={auctionsAppLink} target="_blank" rel="noopener noreferrer">
          <Button
            btnStyle="sca-primary"
            customClass="btn-icon-right"
            icon="gavel"
          >
            Register Now
          </Button>
        </a>
      </div>
    </div>
  )
}

export default OnlineAuctions
